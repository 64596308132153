<template>
    <el-descriptions :column="2" border>
        <el-descriptions-item label="Target Order ID:" >
            <template v-for="orderId in targetOrderIds" :key="orderId">
                <strong v-if="item['target_id']"> {{ item['target_id'] }}</strong>
                <strong v-if="!item['target_id']">
                    <template v-for="orderId in targetOrderIds" :key="orderId">
                        <span v-if="item[orderId] != undefined && item[orderId] != ''">
                        {{ item[orderId] }}
                        </span>
                    </template>
                </strong>
            </template>
        </el-descriptions-item>
        <el-descriptions-item label="Hub Order ID:">
            <strong>{{ item['hub_id'] || item[hubOrderId] }}</strong>
        </el-descriptions-item>
        <el-descriptions-item label="Date created:">
            <strong>
            {{ new Date(item.dateCreated).toLocaleString() }}
            </strong>
        </el-descriptions-item>
        <el-descriptions-item label="Currency:" ><strong>{{ item.currency }}</strong></el-descriptions-item>
        <!-- <el-descriptions-item label="Shipping First Name:" ><strong>{{ item.shippingFirstName }}</strong></el-descriptions-item>
        <el-descriptions-item label="Shipping Last Name:" ><strong>{{ item.shippingLastName }}</strong></el-descriptions-item>
        <el-descriptions-item label="Shipping Address Line 1:" ><strong>{{ item.shippingAddressLine1 }}</strong></el-descriptions-item>
        <el-descriptions-item label="Shipping Address Line 2:" ><strong>{{ item.shippingAddressLine2 }}</strong></el-descriptions-item>
        <el-descriptions-item label="Shipping City:" ><strong>{{ item.shippingCity }}</strong></el-descriptions-item>
        <el-descriptions-item label="Shipping Region:" ><strong>{{ item.shippingRegion }}</strong></el-descriptions-item>
        <el-descriptions-item label="Shipping Country:" ><strong>{{ item.shippingCountry }}</strong></el-descriptions-item>
        <el-descriptions-item label="Shipping Postal Code:" ><strong>{{ item.shippingPostalCode }}</strong></el-descriptions-item> -->
        <el-descriptions-item label="Shipping Method:" ><strong>{{ item.shippingMethod }}</strong></el-descriptions-item>
        <el-descriptions-item label="Payment Method:" ><strong>{{ item.paymentMethod }}</strong></el-descriptions-item>
    </el-descriptions>
    <div style="margin-top: 30px">
        <h3>Products</h3>
    </div>
    <el-table
        :data="item.product"
        style="width: 100%">
        <el-table-column
            prop="sku"
            label="SKU">
        </el-table-column>
        <el-table-column
            label="Item Price">
            <template #default="scope">
                <span> {{ parseFloat(+scope.row.price.$numberDecimal).toFixed(2) }}</span>
            </template>
        </el-table-column>
        <el-table-column
            prop="quantity"
            label="Quantity">
        </el-table-column>
        <el-table-column
            label="Row Total">
            <template #default="scope">
                <span> {{ parseFloat(+scope.row.price.$numberDecimal *  +scope.row.quantity).toFixed(2) }}</span>
            </template>
        </el-table-column>
    </el-table>
    <div style="margin-top: 20px">
        <el-descriptions title="Price" :column="3" border>
            <el-descriptions-item label="Shipping:" ><strong>{{ parseFloat(+item.totalShippingPrice.$numberDecimal).toFixed(2) || 0.00 }}</strong></el-descriptions-item>
            <el-descriptions-item label="Tax:" ><strong>{{ parseFloat(+item.totalTaxPrice.$numberDecimal).toFixed(2) || 0.00 }}</strong></el-descriptions-item>
            <el-descriptions-item label="Total:" ><strong>{{ parseFloat(+item.totalPrice.$numberDecimal).toFixed(2) || 0.00 }}</strong></el-descriptions-item>
        </el-descriptions>
    </div>
    <div v-if="item.history && item.history.length" style="margin-top: 20px">
      <p style="font-weight: 700; font-size: 16px; margin-bottom: 24px">Sync Error</p>
      <div style="max-height: 300px; overflow-x: hidden; overflow-y: auto; text-align: justify;">
        <div v-for="(obj, index) of item.history" :key="index" >
          <strong>Time:</strong> {{ obj.time }} <br/>
          <strong>Content:</strong> {{ obj.error }}
          <el-divider v-if="index < item.history.length" border-style="dashed" />
        </div>
      </div>
    </div>
</template>

<script>
import axios from "@/plugins/axios";
import { Options, Vue } from "vue-class-component";
@Options({
    props: {
        item: {},
    },
})
export default class ModalItem extends Vue {

    headers = {
        "token-user": localStorage.getItem("tokenUser"),
        "account": localStorage.getItem("account"),
    };
    connectData = {}
    hubOrderId = ''
    targetOrderIds = []
    created (){
        this.getListConnect()
    }
    async getListConnect() {
        let res = await axios.get("user/connect/list", {
            headers: this.headers,
        });
        if (res.data) {
            this.connectData = res.data;
            for (let connect of this.connectData) {
                if (connect.connectFrom) {
                this.hubOrderId = connect.type + "OrderId"
                } else {
                    if (this.targetOrderIds.indexOf(connect.type + "OrderId") == -1) {
                        this.targetOrderIds.push(connect.type + "OrderId")
                    }
                }
            }
        }
    }
}

</script>  